/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import logo from "../assets/images/logo-light.png";

export default class Login extends Component {
  render() {
    //const [username, setUserName] = useState("");
    //const [password, setPassword] = useState("");
    // const [loggedIn, setLoggedIn] = useState(false);

    const loginClick = async (e) => {};

    return (
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <form>
              <div className="text-center">
                <img src={logo} alt="" />
              </div>
              <br></br>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter username"
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                />
              </div>
              <div className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className="d-grid">
                <button type="button" className="btn btn-primary">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
