import React from "react";
import {getImageUrl} from "./Utils";


const Records = ({data}) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Img</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">City</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.id}>
                        <td>{item.id} </td>
                        <td>
                            {/* <img src={IMG_PATH + item.imageId + "s.jpg"} title={item.first_name} /> */}
                            <img src={getImageUrl(item)} title={item.first_name} />
                        </td>
                        <td>{item.first_name} </td>
                        <td>{item.last_name} </td>
                        <td>{item.city} </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Records;
