import React from 'react'
import './statics/bootstrap.min.css'
import './App.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Login from './components/login'
import ListIndex from './components/list'
import SignUp from './components/signup'
function App() {
  return (
    <Router>      
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/list" element={<ListIndex />} />
              <Route path="/sign-up" element={<SignUp />} />
            </Routes>          
    </Router>
  )
}
export default App