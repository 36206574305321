/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";

import Records from "./Records";
import Pagination from "./Pagination";
import { getImageUrl } from "./Utils";

import { Link } from "react-router-dom";

function ListIndex() {
  // To hold the actual data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  //////////////////////////////////////

  useEffect(() => {
    axios
      .get("data.json")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => {
        alert("There was an error while retrieving the data");
      });
  }, []);

  //////////////////////////////////////

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage);

  //////////////////////////////////////

  return (
    
          <div>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top">
          <div className="container">
            <Link className="navbar-brand" to={'/sign-in'}><h2> Simple Pagination </h2></Link>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              
            </div>
          </div>
        </nav>
            <div className="container mt-5 datalist">
              
              <Records data={currentRecords} />
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        
  );

  //////////////////////////////////////
}

export default ListIndex;
